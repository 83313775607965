/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from "dayjs";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { useTimer } from "react-timer-hook";

import { isEmpty } from "_dash";
import { getTxUrl } from "utils";
import { getContractConfig } from "utils/contract";
import { CONTRACTS, NETWORK } from "global_constants";

import Minter from "adpaters/minter";
import Proofs from "proofs/gardenClaimProofs.json";

import Faq from "components/faq";
import Button from "components/button";
import ProviderHOC from "hoc/provider";
import useConnect from "hooks/useConnect";
import useProvider from "hooks/useProvider";
import AlertModal from "components/alertModal";

import cloudSvg from "assets/graphics/Clouds_1.svg";
import cloudBackSvg from "assets/graphics/Group-105.svg";
import gardenGif from "assets/graphics/Gardens-mint.gif";
import { ReactComponent as LogoSvg } from "assets/graphics/Logo.svg";

import "./index.css";

const App = ({ provider, setProvider }) => {
  const contracts = useProvider(provider);
  const [isConnected, , doConnect, connection] = useConnect(provider);

  const [userProof, setUserProof] = useState([]);

  const [txHash, setTxHash] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [minting, setMinting] = useState(false);

  const [userMinted, setUserMinted] = useState(0);
  const [currentRound, setCurrentRound] = useState(-1);

  const { seconds, hours, minutes, days, restart } = useTimer({
    autoStart: false,
    onExpire: () => console.warn("stage expires"),
    expiryTimestamp: dayjs().add(1, "days").valueOf(),
  });

  const userCanClaimAmount = (userProof.length > 1 && userProof[1]) || 0;

  useEffect(() => {
    if (connection) {
      setProvider(connection);
    }
  }, [connection]);

  function initialize(contract) {
    const mintObj = new Minter(contract);

    mintObj.getStartTime().then((startTime) => {
      const diff = parseInt(Math.max(dayjs().unix() - startTime.toNumber(), 0));
      if (diff > 0) {
        restart(
          dayjs()
            .add(172800 - diff, "seconds")
            .valueOf(),
          true
        );
      }
    });

    mintObj.getActiveRound().then((round) => {
      setCurrentRound(round.toNumber());
    });
  }

  async function getGardenMintInfo() {
    if (!isEmpty(contracts) && !isEmpty(provider) && provider.address) {
      const mintObj = new Minter(contracts[CONTRACTS.GARDENMINTER]);
      mintObj.getActiveRound().then((round) => {
        setCurrentRound(round.toNumber());
      });

      mintObj.addressToClaim(provider.address).then((claimed) => {
        setUserMinted(claimed.toNumber());
      });
    }
  }

  useEffect(() => {
    const config = getContractConfig(
      NETWORK.TARGET_CHAIN_ID.toString(),
      CONTRACTS.GARDENMINTER
    );
    if (config) {
      const provider = new ethers.providers.JsonRpcProvider(
        process.env.REACT_APP_RPC_URL
      );
      const contract = new ethers.Contract(
        process.env.REACT_APP_CONTRACT_ADDRESS,
        config.abi,
        provider
      );
      initialize(contract);
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(contracts)) {
      setUserProof(Proofs.proofs[provider.address.toLowerCase()] || []);
      getGardenMintInfo();
      provider.instance.on("block", () => {
        initialize(contracts[CONTRACTS.GARDENMINTER]);
        getGardenMintInfo();
      });
      return () => provider.instance.off("block");
    }
  }, [contracts]);

  async function onMint() {
    if (userProof.length) {
      if (currentRound === 2) {
        setMinting(true);
        const mintObj = new Minter(
          contracts[CONTRACTS.GARDENMINTER],
          provider.address
        );
        const supplyLeft = (await mintObj.getSupplyLeft()).toNumber();
        if (supplyLeft > 0 && userCanClaimAmount - userMinted > 0) {
          try {
            const tx = await mintObj.claimMint(
              provider.signer,
              Math.min(userCanClaimAmount - userMinted, supplyLeft),
              userProof[0]
            );
            setTxHash(tx.hash);
            await tx.wait();
          } catch (e) {
            setErrorMsg("An error occurred. Please try again.");
          }
        } else {
          setErrorMsg("No supply left.");
        }
      } else {
        setErrorMsg("Claiming starts from 04:30CEST 12/07/22");
      }
    } else {
      setErrorMsg("You are not eligible to claim a garden NFT.");
    }
    setMinting(false);
    setTxHash(undefined);
  }

  return (
    <>
      <div className="h-full">
        <div
          className="py-12"
          style={{ backgroundImage: `url(${cloudBackSvg})` }}
        >
          <LogoSvg className="m-auto" />
          <div className="container m-auto mt-12">
            <div className="text-center">
              <p className="font-arco text-7xl title-stroke text-white">
                Claim my
              </p>
              <p className="font-arco text-7xl title-stroke text-white">
                <span className="text-[#ffec3e]">Oasis garden</span> NFT
              </p>
            </div>
            <div className="flex gap-12 mt-20 justify-center p-4 flex-col md:flex-row md:p-8">
              <img
                src={gardenGif}
                alt="FlowerFamGarden"
                className="md:h-[490px] rounded-[20px]"
              />
              <div className="bg-white p-6 md:p-8 flex-1 max-w-[500px] pr-2 rounded-[40px]">
                <div className="text-textColor font-morh-sb text-lg">
                  <p>You can start claiming starting from</p>
                  <p>
                    from <span className="font-morh-b">04:30CEST 12/07/22</span>
                  </p>
                </div>
                <div className="text-textColor font-morh-sb text-lg mt-4">
                  48 hour countdown starting from 04:30CEST 12/07/22
                </div>
                <div className="flex mt-6 gap-6">
                  <div>
                    <p className="font-morh-sb mb-2">Claimable</p>
                    <p className="bg-green-100 text-green-600 py-2 px-4 text-2xl font-bold rounded-lg">
                      {userCanClaimAmount}
                    </p>
                  </div>
                  <div>
                    <p className="font-morh-sb mb-2">Time Left for claim</p>
                    <p className="bg-red-100 text-red-600 py-2 px-4 text-2xl font-bold rounded-lg">
                      {days}day {hours}:{minutes}:{seconds}
                    </p>
                  </div>
                </div>
                {isConnected ? (
                  <>
                    {userMinted < userCanClaimAmount ? (
                      <>
                        <div className="flex mt-6 gap-6">
                          <Button
                            isPrimary
                            loading={minting}
                            onClick={() => onMint()}
                            classes={[
                              "text-lg",
                              "uppercase",
                              "font-morh-b",
                              "text-center",
                            ]}
                          >
                            Claim my gardens
                          </Button>
                        </div>
                      </>
                    ) : userCanClaimAmount > 0 ? (
                      <div className="text-textColor font-morh-sb text-lg">
                        <p style={{ color: "red" }}>
                          You have claimed your {userCanClaimAmount} Gardens and
                          cannot claim more.
                        </p>
                      </div>
                    ) : (
                      <div className="text-textColor font-morh-sb text-lg">
                        <p style={{ color: "red" }}>
                          You were not selected to claim a Garden NFT.
                        </p>
                      </div>
                    )}
                  </>
                ) : (
                  <Button
                    isPrimary
                    onClick={() => doConnect()}
                    classes={[
                      "mt-8",
                      "uppercase",
                      "!bg-[#ffec3e]",
                      "text-textColor",
                    ]}
                  >
                    Connect wallet
                  </Button>
                )}
                {txHash ? (
                  <div className="mt-8 text-center">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={getTxUrl(txHash)}
                      className="font-morh-sb text-xl"
                    >
                      View TX
                    </a>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <img src={cloudSvg} alt="Clouds" width="100%" />
        <Faq />
      </div>
      <AlertModal
        message={errorMsg}
        visible={!!errorMsg}
        onClose={() => setErrorMsg()}
      />
    </>
  );
};

export default ProviderHOC(App);
