import { useState } from "react";
import { ReactComponent as FaqIconSvg } from "assets/graphics/_.svg";

const FAQ_ITEMS = [
  {
    title: "How many Gardens can I claim?",
    content: <p>1 Garden for every 2 Staked OG Flowers.</p>,
  },
  {
    title: "Who can claim?",
    content: (
      <p>
        1 Garden for every 2 OG Flowers that have been staked in the last 7
        Days.
      </p>
    ),
  },
  {
    title: "Can I claim with mobile? ",
    content: <p>Yes</p>,
  },
  {
    title: "Why 24 hours?",
    content: <p>Community decision.</p>,
  },
  {
    title: "Can I stake + pair my Garden immediately?",
    content: (
      <p>
        UI and Dashboard will be live in the next hours. Keep your eyes open.
      </p>
    ),
  },
  {
    title: "Wen stake?",
    content: <p>In the timeframe of claim and before reveal.</p>,
  },
  {
    title: "Wen reveal?",
    content: <p>After stake. Friday European Evening time.</p>,
  },
  {
    title: "Gas efficient claim?",
    content: <p>Yes.</p>,
  },
  {
    title: "Batch claim?",
    content: <p>Yes.</p>,
  },
];

export default function Faq() {
  const [openFaqItems, setOpenFaqItems] = useState([]);

  function onToggleFaqItem(idx) {
    const index = openFaqItems.indexOf(idx);
    if (index > -1) {
      setOpenFaqItems(openFaqItems.filter((i) => i !== idx));
    } else {
      setOpenFaqItems([...openFaqItems, idx]);
    }
  }

  return (
    <div className="container m-auto pt-16 pb-24 px-4 relative">
      <p className="font-arco text-7xl title-stroke text-[#ffec3e] text-center mb-12">
        faq
      </p>
      <div className="faq-wrapper max-w-[800px] m-auto">
        {FAQ_ITEMS.map((item, idx) => {
          const isOpen = openFaqItems.indexOf(idx) > -1;
          return (
            <div
              key={idx}
              className="faq-item bg-white rounded-3xl my-6 border-textColor border-[3px]"
            >
              <div
                onClick={() => onToggleFaqItem(idx)}
                className="faq-title px-10 pt-6 pb-5 cursor-pointer flex justify-between"
              >
                <p className="font-morh-b text-xl text-textColor">
                  {item.title}
                </p>
                <FaqIconSvg
                  className={`toggle-faq-icon transition-all ${
                    isOpen ? "active" : ""
                  }`}
                />
              </div>
              <div
                className={`faq-content font-morh-m pb-6 px-10 text-textColor ${
                  isOpen ? "block" : "hidden"
                }`}
              >
                {item.content}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
