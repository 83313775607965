export default class Minter {
  constructor(instance, address) {
    this.address = address;
    this.instance = instance;
  }

  getStartTime() {
    return this.instance.startTimeClaim();
  }

  getSupplyLeft() {
    return this.instance.getSupplyLeft();
  }

  claimMint(signer, amount, proofs) {
    return this.instance.connect(signer).claimMint(proofs, amount, amount);
  }

  getActiveRound() {
    return this.instance.getActiveRound();
  }

  addressToClaim(address) {
    return this.instance.addressToClaim(address);
  }
}
